@import url('./all.css');

.gg-box {
  color: red;
}

.gg-log-out {
  color: red;
}

.gg-edit-markup {
  color: red;
}

.gg-home {
  color: red;
}