@media screen and (min-width: 1024px) {
    .item-code-box{
        border-top: none;
        border-left: 1px solid;
        border-right: none;
        border-bottom: 1px solid;
        border-color: #b22222;
    }
    .item-name-box{
        border-top: none;
        border-left: none;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #b22222;
    }
}
@media screen and (max-width: 1023px) {
    .item-code-box{
        border-top: none;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: none;
        border-color: #b22222;
    }
    .item-name-box{
        border-top: none;
        border-left: 1px solid;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #b22222;
    }
}