.title_box {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #b22222;
    background-color: #b22222;
    color: white;
}

.content_box {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: none;
    border-color: #b22222;
}
.content_end_box {
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #b22222;
}
